<!--
 * @Descripttion: 日志
 * @version: 1.0.0
 * @Author: liujx@imyfone.cn
 * @Date: 2022-01-14 18:45:08
 * @LastEditors: liujx@imyfone.cn
 * @LastEditTime: 2025-02-10 13:56:49
-->
<template>
  <div class="journal-container">
    <div class="journal-top border-6">
      <div>
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="4" y="4" width="12" height="14" rx="1" stroke="#7972F0" stroke-width="2"/><rect x="6.5" y="9.5" width="7" height="1" rx=".5" stroke="#7972F0"/><rect x="6.5" y="13.5" width="7" height="1" rx=".5" stroke="#7972F0"/><rect x="7.5" y="1.5" width="1" height="5" rx=".5" stroke="#7972F0"/><rect x="11.5" y="1.5" width="1" height="5" rx=".5" stroke="#7972F0"/></svg>
        <span>日志</span>
        <ul class="journal-tab">

            <li v-for="({type, name}, index) in journalType" :key="type" :class="{'journal-item': true, 'active': type === activeType }" @click="changeJournalType(journalType[index])">
              <span>{{name}}</span>
            </li>

            <li class="border"></li>

            <li class="journal-item search-button" @click="openSearch" ref="searchContentRef">
              <span>搜索</span>
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.25 13.3226V8.29032L13.0986 3.51207C13.496 2.8455 13.0157 2 12.2396 2H3.76037C2.98434 2 2.50405 2.8455 2.90142 3.51206L5.75 8.29032V15" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              <div class="search-card" ref="searchRef" v-show="searchStatus">
                <el-form size="small">
                    <el-form-item label="日志类型">
                      <el-select v-model="params.template_id" placeholder="全部">
                          <el-option
                            v-for="item in templateList"
                            :key="item.value"
                            :label="item.template_name"
                            :value="item.template_id"
                          >
                            <span class="type-icon">
                              <svg v-show="params.template_id === item.template_id" width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 4l2.71 3L9 1" stroke="#333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                            </span>
                            <span>{{ item.template_name }}</span>
                          </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="提交日期">
                      <el-date-picker
                        v-model="searchDate"
                        type="daterange"
                        unlink-panels
                        value-format="YYYY-MM-DD"
                        range-separator="~"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                      />
                    </el-form-item>
                    <el-form-item label="提交人员" v-show="params.type !== '1'">
                      <p :class="['user-select', {'selected-name': receiveUserName}]" @click="selectVisible = true">
                        {{ receiveUserName || '请选择' }}
                        <i class="arrow-down-icon"></i>
                      </p>
                    </el-form-item>
                    <el-form-item>
                      <div class="button-list">
                        <el-button class="cancel" @click.stop.prevent="cancelSearch">取消</el-button>
                        <el-button class="success" @click.stop.prevent="postSearch">确定</el-button>
                      </div>
                    </el-form-item>
                  </el-form>
              </div>
            </li>
        </ul>
      </div>

      <div>
        <el-checkbox class="null-read" label="未读" v-model="readStatusCheckbox" @change="changeJournalType()"/>
        <el-button type="primary" size="mini" @click="gotoJournal">写日志</el-button>
      </div>
    </div>

    <div class="journal-list" v-loading="isLoading">
      <TeamCatalogue v-if="activeType === '2'"
        class="journal-list-left border-6"
        @select="queryUserJournal"
        :data="teamData">
      </TeamCatalogue>
      <JournalCatalogue v-else
        class="journal-list-left border-6"
        ref="journalCatalogueRef"
        :journalList="journalList"
        :activeIndex="activeIndex"
        :showLoadMore="showLoadMore"
        @loadMore="loadMore">
      </JournalCatalogue>
      <JournalContent class="journal-list-right"
        ref="journalContentRef"
        :onlyPreview="false"
        :journalList="journalList"
        :journalBtnVisible="journalBtnVisible"
        :activeIndex="activeIndex"
        @scoller="scollerRightJournal"
        @delete="deleteJournal"
        :activeType="activeType"
        :depFlag="depFlag"
      ></JournalContent>
    </div>
    <!-- 选择用户范围 -->
    <el-dialog v-model="selectVisible" custom-class="select-user-dialog"
      :width="651" title="选择日志提交人员" :show-close="false" @close="hideSelectUser">
        <SelectUserRange
          ref="selectUserRef"
          :receiveIds="receiveIds"
          :noMaxNum="true"
        ></SelectUserRange>
        <template #footer>
          <div class="button-box">
            <el-button class="back-button" type="primary" size="small" @click="hideSelectUser" >取消</el-button>
            <el-button class="sure-button" type="primary" size="small" @click="addReceiveUser">确定</el-button>
          </div>
      </template>
      </el-dialog>
  </div>
</template>
<script>
import { reactive, toRefs, onMounted, computed, onUnmounted } from 'vue'
import JournalContent from './JournalContent'
import JournalCatalogue from './JournalCatalogue'
import TeamCatalogue from './TeamCatalogue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import eventBus from '@/utils/eventBus.js'
import { getTeamList, getJournalList, getTemplateList, getJournalLogStatu, getTeamUserList } from '@/apis/journal.js'

export default {
  components: {
    JournalContent,
    JournalCatalogue,
    TeamCatalogue
  },
  setup () {
    const router = useRouter()
    const store = useStore()
    const state = reactive({
      journalType: [
        { type: '0', name: '全部' },
        { type: '1', name: '我发出的' }
        // { type: '3', name: '无筛选' }
      ],
      activeType: '0',
      isLoading: true,
      templateList: [{ template_id: '', template_name: '无筛选' }],
      templateVisible: false,
      params: {
        limit: 20,
        page: 1,
        type: '0', // 0-全部接受的;1-我发出的;2-我的团队;3-筛选;
        dept_id: '',
        user_id: '',
        read_status: '0', // 阅读状态|0-全部,1-未读;
        template_id: '', // 选中的模板,
        start_time: '', // 开始时间
        end_time: '' // 结束时间
      },
      searchDate: [], // 时间
      readStatusCheckbox: false, // 阅读按钮状态
      journalList: [], // 日志列表
      activeIndex: 0, // 当前高亮日志ID
      journalTotal: 0, // 日志总数
      teamData: {},
      templateListRef: null,
      journalCatalogueRef: null,
      journalContentRef: null,
      journalBtnVisible: false, // 我是否写过日志,
      receiveNewJournal: computed(() => { return (store.state.work.receiveNewJournal || 0) }), // 是否收到新日志
      scollerQueryedPage: [], // 已经查询过的页码
      showLoadMore: true,
      searchRef: null,
      searchStatus: false, // 搜索状态
      searchContentRef: null,
      depFlag: '',
      selectUserRef: null,
      selectVisible: false, // 日志提交人选择弹窗可见
      receiveList: [],
      receiveIds: computed(() => state.receiveList.map(item => item.ding_user_id || item.id)),
      receiveUserName: computed(() => state.receiveList.map(item => item.name).join('、'))
    })

    // function callball (e) {
    // const className = e.target.className
    // if (state.searchRef && !state.searchRef.contains(e.target) && (className !== 'journal-item search-button' || className !== 'search-card')) {
    // console.log(e.target.className)
    // state.searchStatus = false
    // }
    // }

    // document.addEventListener('click', callball, true)

    const handleClick = (e) => {
      // console.log(e.target.className)
      const tempRefNaN = ['el-form-item__label', 'button-list', 'search-card', 'el-form', 'el-form-item__content', 'el-range-separator', 'el-range-input', 'el-input__icon el-range__icon el-icon-date', 'el-date-editor el-range-editor el-input__inner el-date-editor--daterange el-range-editor--small is-active', 'user-select', 'user-select selected-name', 'arrow-down-icon']
      // 弹窗模式点击默认不处理
      if (e.target.className === 'el-overlay dialog-fade-leave-from dialog-fade-leave-active') return
      if (tempRefNaN.includes(e.target.className)) {
        return (state.searchStatus = true)
      }
      if (state.searchContentRef && !state.searchContentRef.contains(e.target)) {
        state.searchStatus = false
      }
    }
    onMounted(() => {
      getJournal()
      getMyTeam()
      getTemplate()
      getMyJournal() // 查询我发出的
      // setUnReadCount() // 查询未读数量
      document.addEventListener('click', handleClick)
    })

    onUnmounted(() => {
      document.removeEventListener('click', handleClick)
    })

    const openSearch = () => {
      state.searchStatus = !state.searchStatus
    }

    // 改变日志类型
    const changeJournalType = (obj) => {
      if (obj && (obj.type === '0' || obj.type === '2')) {
        state.params.dept_id = ''
        const userIds = state.receiveList.map(item => item.id)
        state.params.user_id = userIds.length ? userIds : ''
      }
      if (obj && obj.type === '1') {
        state.params.dept_id = ''
        state.params.user_id = ''
      }
      state.params.read_status = state.readStatusCheckbox ? 1 : 0
      if (obj && obj.type !== '3') {
        // 排除掉模板筛选
        state.activeType = obj.type
        state.params.type = obj.type
      }

      state.params.page = 1
      state.activeIndex = 0
      state.journalContentRef.journalRefList = []
      // 清空记录已滚动的index列表
      state.journalContentRef.scrollIndexs = []
      if (!obj || obj.type !== '3') {
        // state.params.template_id = ''
        state.isLoading = true
        state.journalList = []
        const readType = !obj || obj.type === '2' ? 'team' + (+new Date()) : ''
        getJournal(state.readStatusCheckbox ? 'noread' + (+new Date()) : readType)
      } else {
        state.templateVisible = !state.templateVisible
      }
      state.searchStatus = false
    }
    function gotoJournal () {
      router.push({ name: 'writeJournal' })
    }
    // 获取我的团队信息
    const getMyTeam = () => {
      const data = { type: 1 }
      getTeamList(data).then(res => {
        if (res.code === 0) {
          state.teamData = res.data
          // if (state.teamData.is_leader === 1) { // 是否团队管理员
          state.journalType.splice(2, 0, { type: '2', name: '我的团队' })
          // }
        }
      })
    }

    // 获取模板列表
    const getTemplate = () => {
      const data = { user_id: store.state.userInfo.userInfos.user_id }
      getTemplateList(data).then(res => {
        if (res.code === 0) {
          state.templateList.push(...res.data.no_use_template)
          if (res.data.use_template && res.data.use_template.length > 0) {
            state.templateList.push(...res.data.use_template)
            store.commit('work/setTemplateList', {
              use_template: res.data.use_template,
              no_use_template: res.data.no_use_template
            })
          } else { // 从未写过日志
            const list = res.data.no_use_template
            store.commit('work/setTemplateList', {
              use_template: list.slice(0, 4),
              no_use_template: list.slice(4)
            })
          }
        } else {
        }
      })
    }

    // 获取日志列表
    const getJournal = (isDep) => {
      state.params.page === 1 && (state.journalList = [])
      state.depFlag = isDep || ''

      getJournalList(state.params).then(res => {
        state.isLoading = false
        if (res.code === 0) {
          if (state.params.page === 1) {
            state.journalList = res.data.data
            // state.journalTotal = res.data.total
          } else {
            state.journalList.push(...res.data.data)
          }
          state.showLoadMore = res.data.data.length === 20
          // 查询未读为0，清空小红点
          // if (state.params.type === '4' && state.journalTotal === 0) {
          //   store.commit('work/setReceiveNewJournal', 0)
          // }
          // 查询第一篇日志的评论/高亮阅读第一篇日志
          if (state.params.page === 1) {
            state.scollerQueryedPage = []
            state.journalList[0].is_active = true
            state.journalContentRef.getCommentList(state.journalList[0].id)
            state.journalCatalogueRef ? state.journalCatalogueRef.readJournal(state.journalList[0]) : eventBus.$emit('rightReadLog', state.journalList[0])
          }
        }
      }).catch(e => {
        state.isLoading = false
      })
    }

    // 获取未读日志总数

    // const setUnReadCount = () => {
    //   getUnReadCount().then(res => {
    //     let count = 0
    //     res.code === 0 && (count = res.data.unread_count)
    //     store.commit('work/setAllReceiveNewJournal', count) // 设置未读数量
    //   })
    // }

    // 我写的日志
    const getMyJournal = () => {
      getJournalLogStatu().then(res => {
        if (res.code === 0) {
          state.journalBtnVisible = res.data.write_log_status === 0 // 我是否写过日志
        }
      })
    }

    // 筛选模板
    const getJournalByTemplate = (template) => {
      state.params.page = 1
      state.params.template_id = template.template_id
      // 修改最后一个，可能是3或者4
      state.journalType[state.journalType.length - 1].name = template.template_name
      state.journalList = []
      state.templateVisible = false
      state.isLoading = true
      getJournal('template' + (+new Date()))
    }

    // 删除
    const deleteJournal = (obj) => {
      // 删除数据, 总数减一
      state.journalList = state.journalList.filter(item => item.id !== obj.id)
      // state.journalTotal--
      // 高亮,删除会先触发右侧滚动事件，所以这里做一下延时
      setTimeout(() => {
        const index = state.journalList.findIndex(item => item.is_active)
        let nextObj = {}
        if (index === -1) { // 无高亮的，高亮第一个
          nextObj = state.journalList[0]
          nextObj.is_active = true
        } else if (index !== obj.index) { // 高亮不正确，校正
          nextObj = state.journalList[obj.index]
          nextObj.index = obj.index
        }
        state.journalCatalogueRef && state.journalCatalogueRef.clickournal(nextObj)
      }, 100)
    }

    const loadMore = () => {
      state.params.page += 1
      getJournal()
    }

    // 查询部门或者单个用户的日志列表
    const queryUserJournal = (obj) => {
      if (obj.type === 'dept') {
        state.params.dept_id = obj.id
        state.params.user_id = ''
      } else {
        // state.params.dept_id = ''
        state.params.user_id = obj.id
      }

      state.params.page = 1
      state.params.type = 2
      state.journalList = []
      state.receiveList = []
      state.activeIndex = 0
      getJournal(String(obj.id) + (+new Date()))
    }
    // 右侧滚动
    const scollerRightJournal = (index) => {
      // 高亮
      state.activeIndex = index
      if (state.journalCatalogueRef) { // 有左侧目录
        // // 滚动
        // // const height = index === 0 ? 0 : 40 * (index + 1) - 200
        // const height = document.querySelectorAll('.journal-ul li')[index].offsetTop - 20
        // // console.log(state.journalCatalogueRef.scrollbarRef.wrap.style.transition = 'all 3s')
        // state.journalCatalogueRef.scrollbarRef.setScrollTop(height)
      } else { // 我的团队下拉加载
        // 只触发一次
        const hasNext = state.journalList.length % 10 === 0
        if (hasNext) {
          const isLastIndex = [5, 1].includes(state.journalList.length - index) // 提前查询
          const isQueryed = state.scollerQueryedPage.includes(state.params.page + 1)
          if (isLastIndex && !isQueryed) {
            state.scollerQueryedPage.push(state.params.page + 1)
            setTimeout(() => {
              loadMore()
            }, 300)
          }
        }
      }
    }

    // 搜索
    const postSearch = () => {
      const [startTime, endTime] = state.searchDate ? state.searchDate : ['', '']
      state.params.start_time = startTime
      state.params.end_time = endTime
      changeJournalType({ type: state.activeType })
      // state.searchStatus = false
    }

    // 取消搜索
    const cancelSearch = () => {
      state.searchStatus = false
    }

    // 增加用户
    const addReceiveUser = () => {
      state.receiveList = []
      state.selectUserRef.rightData.forEach(item => {
        if (item.dept_id) {
          getTeamUserList({ dept_id: item.dept_id }).then(res => {
            res.data.forEach(user => {
              setReceiveUser(user)
            })
          })
        } else {
          setReceiveUser(item)
        }
      })
      hideSelectUser()
    }
    // 设置日志接收人是否可删除
    const setReceiveUser = (item) => {
      const user = JSON.parse(JSON.stringify(item))
      // 去重添加
      !state.receiveIds.includes(user.ding_user_id) && state.receiveList.push(user)
    }
    // 关闭用户选择弹窗
    const hideSelectUser = () => {
      state.selectVisible = false
      // 重置数据
      state.selectUserRef.resetData()
    }

    return {
      ...toRefs(state),
      changeJournalType,
      gotoJournal,
      deleteJournal,
      loadMore,
      getJournalByTemplate,
      queryUserJournal,
      scollerRightJournal,
      cancelSearch,
      postSearch,
      openSearch,
      hideSelectUser,
      addReceiveUser
    }
  }
}
</script>
<style lang="less" scoped>
  :deep(.el-loading-mask) {
    z-index: 999;
  }
  .journal-container{
    margin-right: 8px;
  }
  .journal-top{
    display: flex;
    justify-content: space-between;
    background: @colorWhite;
    padding: 8px 18px;
    >div{
      display: flex;
      align-items: center;
      position: relative;
      >span{
        color:@active-text-color;
        font-size: 14px;
        font-weight: bold;
      }
    }
  }

  .search-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 148px;
  }

  .journal-tab{
    display: flex;
    margin-left: 18px;
    align-items: center;
    .journal-item {
      font-size: 14px;
      background: @bgColor;
      color: @second-text-color;
      padding: 5px 12px;
      margin-right: 16px;
      border-radius: 44px;
      cursor: pointer;
      border: 1px solid #F1F1F1;
      &.active{
        color: @active-text-color;
        border:1px solid @active-text-color;
        font-weight: bold;
      }
      img{
        margin-left: 9px;
      }
    }
    .border {
      margin-right: 16px;
      width: 1px;
      height: 24px;
      background-color: #F1F1F1;
    }
  }

  .type-icon {
    display: inline-block;
    margin-right: 8px;
    width: 10px;
  }

  .search-card {
    position: absolute;
    padding: 28px 16px 16px;
    width: 342px;
    right: -82px;
    top: 46px;
    z-index: 2001; // 避免被loading遮罩层覆盖
    background: @colorWhite;
    border-radius: 6px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    :deep(.el-form) {
      .el-input--small .el-input__inner,
      .el-range-editor--small.el-input__inner {
          padding: 0 16px;
          width: 250px;
          border-radius: 32px;
          background: @bgColor;
          border: 1px solid #F1F1F1;
       }
      .el-date-editor {
        input,
        .el-range-separator {
          background: @bgColor;
        }
        .el-range-separator {
          padding: 0;
        }
      }
       .el-form-item--small .el-form-item__label {
         color: #999;
         font-size: 13px;
       }

       .el-form-item--small.el-form-item:last-child {
         margin-bottom: 0;
       }

       input:nth-child(2) {
         text-align: left
       }

       .button-list {
         display: flex;
         justify-content: flex-end;

         .el-button {
            padding: 0;
            width: 68px;
            height: 24px;
            min-height: 24px;
            color: #fff;
            font-size: 12px;
            border-radius: 51px;
            border: none;
            &.success {
              background: @active-text-color;
            }
            &.cancel {
              background: #999;
            }
         }
       }

       .center {
         display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
       }

      .el-icon-arrow-up {
        .center();
        margin-right: 11px;
        &::before {
          content: '';
          display: block;
          width: 12px;
          height: 8px;
          transform: rotate(180deg);
          background: url(../../../../assets/img/arrow-down-new.svg) no-repeat;
        }
      }

      .el-icon-date {
        .center();
        order: 1;
        &::before {
          content: "";
          display: block;
          width: 20px;
          height: 20px;
          background: url(../../../../assets/img/datepicter-new.svg) no-repeat;
        }
      }

    }

    &::after {
      content: " ";
      position: absolute;
      margin: auto;
      left: 0;
      right: 0;
      top: -5px;
      width: 10px;
      height: 10px;
      transform: rotate(45deg);
      border-right-color: transparent;
      border-bottom-color: transparent;
      background: #fff;
    }
  }
  .template-list{
    position: absolute;
    right: -22px;
    top: 36px;
    width: 124px;
    padding: 4px 0;
    background: @colorWhite;
    z-index: 2001; // 避免被loading遮罩层覆盖
    li{
      cursor: pointer;
      color: @default-text-color;
      padding: 8px 15px 5px;
      font-size: 13px;
      &.active svg{
        visibility: inherit;
      }
      svg{
        visibility: hidden;
        margin-right: 8px;
      }
      &.active,&:hover{
        background: #F1F4F4;
      }
    }
  }
  .el-button{
    font-size: 14px;
  }
  .null-read {
    margin-right: 42px;
  }
  .journal-list {
    display: flex;
    margin-top: 8px;
    min-height: 700px;
    height: calc(100vh - 180px);
    .journal-list-left{
      min-width: 204px;
      padding: 8px;
      background-color: @colorWhite;
      margin-right: 8px;
    }
    :deep(.journal-list-right){
      width: 626px;
      display: flex;
      min-width: 0;

      .el-scrollbar__wrap {
        width: 100%;
      }
    }

    :deep(.okr-content-history) {
      font-size: 14px;
      line-height: 25px;
    }
  }
  :deep(.select-user-dialog){
    background: #F6F9FA;
    border-radius: 6px;
    .el-dialog__header{
      padding: 16px 24px;
    }
    .el-dialog__body{
      padding: 0px 24px 12px;
      font-size: 13px;
      color: @default-text-color;
    }
    .el-dialog__title{
      font-weight: bold;
      font-size: 16px;
      line-height: 21px;
    }
    .el-dialog__footer{
      padding: 0 25px 12px;
    }
  }
  .user-select{
    cursor: pointer;
    height: 100%;
    width: 100%;
    max-width: 250px;
    border: 1px solid #F1F1F1;
    padding: 0 34px 0 16px;
    width: 250px;
    border-radius: 32px;
    background: #f6f9fa;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &.selected-name{
      color: var(--el-text-color-regular);
    }
    .arrow-down-icon{
      position: absolute;
      top: 14px;
      right: 23px;
      display: inline-block;
      width: 12px;
      height: 8px;
      background: url(../../../../assets/img/arrow-down.svg) no-repeat;
    }
  }

  .button-box{
    .el-button{
      width: 85px;
      height: 32px;
      border-radius: 30px;
      line-height: 30px;
      padding: 0;
      border: none;
    }
    .back-button{
      color: @active-text-color;
      background: #E4E3FC;
    }
    .sure-button{
      color: #FFFFFF;
      background: @active-text-color;
    }
  }

</style>
