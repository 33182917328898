// OKR状态常量
export const OKR_STATUS = {
  ADD: 0,
  DRAFT: 1,
  CHECKING: 2,
  REJECT: 3,
  PASS: 4,
  EVALUATION: 5,
  COMPLETE: 6,
  CHECKING_AGAIN: 7
}

// OKR状态类型
export const OKR_STATUS_TYPE = {
  ADD: 'add',
  DRAFT: 'draft',
  CHECKING: 'checking',
  REJECT: 'reject',
  PASS: 'pass',
  EVALUATION: 'evalution',
  COMPLETE: 'complete'
}

// OKR状态值映射
export const OKR_STATUS_MAP = [
  { code: OKR_STATUS.ADD, value: OKR_STATUS_TYPE.ADD },
  { code: OKR_STATUS.DRAFT, value: OKR_STATUS_TYPE.DRAFT },
  { code: OKR_STATUS.CHECKING, value: OKR_STATUS_TYPE.CHECKING },
  { code: OKR_STATUS.REJECT, value: OKR_STATUS_TYPE.REJECT },
  { code: OKR_STATUS.PASS, value: OKR_STATUS_TYPE.PASS },
  { code: OKR_STATUS.EVALUATION, value: OKR_STATUS_TYPE.EVALUATION },
  { code: OKR_STATUS.COMPLETE, value: OKR_STATUS_TYPE.COMPLETE },
  { code: OKR_STATUS.CHECKING_AGAIN, value: OKR_STATUS_TYPE.CHECKING }
]

// OKR草稿按钮显示状态
export const OKR_DRAFT_BTN_STATUS = [
  OKR_STATUS_TYPE.ADD,
  OKR_STATUS_TYPE.DRAFT,
  OKR_STATUS_TYPE.REJECT,
  OKR_STATUS_TYPE.PASS
]

// OKR评论显示状态
export const OKR_COMMENT_STATUS = [
  OKR_STATUS_TYPE.PASS,
  OKR_STATUS_TYPE.EVALUATION,
  OKR_STATUS_TYPE.COMPLETE
]

// 咨询类型
export const CONSULT_TYPE = {
  1000000: '36氪',
  1000001: '稀土掘金',
  1000002: '量子位',
  1000003: '雷科技'
}
